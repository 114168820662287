@import "../../styles/colors";
@import "../../styles/fonts";

.componentDetails {
  background-color: $background-highlight;
  height: 100%;
  max-width: 45%;
  border-radius: 5px;
  padding: 0 10px;
  div {
    div {
      padding: 5px;
    }
  }
  .title {
    font-weight: bold;
  }
}

.detailsInner {
  display: flex;
  flex: 1;
  flex-flow: column;
}
.update {
  margin-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  flex: 1;
  overflow: auto;
  border-bottom: 1px solid $border-highlight;
  .title {
    font-weight: bold;
  }
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  width: 100%;
}
.body,
.footer {
  text-align: left;
  padding: 10px;
  font-size: $main-font-size;
  display: flex;
}

.footer {
  color: $secondary-text;
  font-size: $footer-font-size;
  padding-bottom: 0;
}
