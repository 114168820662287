.wrapper {
  min-height: 40px;
  position: relative;
  padding: 20px;
}

.spinner {
  text-align: center;
  margin: 0 auto;
  width: 40px;
  padding-bottom: 15px;
}


