@import "../../styles/colors";


.wrapper {
  text-align: left;
  padding: 25px 25px 0 25px;
  border-radius: 5px;
}
.banner {
  border-radius: 5px;
  color: $base-color;
  font-weight: bold;
  padding: 20px;
}
.banner,
.banner-success {
  background-color: $success;
}
.banner-fail {
  background-color: $failure;
}
.banner-warn {
  background-color: $warning;
}
