
body {
  text-align: center;
  padding: 20px;
}
.App {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.App-body {
  min-height: 200px;
}
.containerBox {
  margin: 20px 10px;

  border: 0 solid ;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  -webkit-box-shadow: rgba(0,0,0,0.2) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.2) 0 0 10px;
  box-shadow: rgba(0,0,0,0.2) 0 0 10px;
}
