$success: #4ACC66; //Operational
$failure: #e74c3c;
$major: #e74c3c; //major outage
$minor: #e67e22; //partial outage
$warning: #f1c40f; //Degraded performance
$maintenance: #3498DB; //under maintenance
$no-impact: #707070;
$icon-default: #000000;

$cloud-call: #6d4afc;
$cloud-call-highlight: #1c0d5a;

$base-color: #FFFFFF;
$background-highlight: #f4f4f4;
$border-highlight: #cccccc;

//Text Colors
$secondary-text: #acacac;
$highlight-text: #FFFFFF;
$link-text: #3498db;


