@import "../../styles/colors";
@import "../../styles/fonts";

.wrapper {
  text-align: left;
  padding: 5px 20px 25px 20px;
  .body {
    color: $secondary-text;
    font-size: $header-font-size;
    a,
    a:visited{
      color: $link-text;
      text-decoration: none;
    }
  }
}
