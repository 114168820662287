@import "../../styles/colors";

.wrapper {
  clear: both;
  display: flex;
  padding: 20px;
}

@media (max-width: 600px) {
  .iconHolder {
    display: none;
  }
}
.incidentDetails {
  text-align: left;
  border: 1px solid;
  flex-grow:1;
  border-radius: 5px;
  .incidentHeader {
    color: $highlight-text;
    background-color: $base-color;
    padding: 10px;
    h2 {
      margin: 0;
    }
  }
}
.bodyStatus {
  font-weight: bold;
}
.incidentDetailsAccordion {
  .incidentHeader {
    padding: 0 10px;
  }
}

.incidentDetails-degraded_performance {
  border-color: $warning;
  .incidentHeader {
    background-color: $warning;
  }
}

.incidentDetails-critical {
  border-color: $failure;
  .incidentHeader {
    background-color: $failure;
  }
}
.incidentDetails-maintenance,
.incidentDetails-under_maintenance, {
  border-color: $maintenance;
  .incidentHeader {
    background-color: $maintenance;
  }
}

.incidentDetails-major,
.incidentDetails-major_outage {
  border-color: $major;
  .incidentHeader {
    background-color: $major;
  }
}

.incidentDetails-minor,
.incidentDetails-partial_outage {
  border-color: $minor;
  .incidentHeader {
    background-color: $minor;
  }
}

.incidentDetails-operational {
  border-color: $success;
  .incidentHeader,
  .MuiAccordionSummary-root {
    background-color: $success;
  }
}


.incidentDetails-none {
  border-color: $no-impact;
  .incidentHeader,
  .MuiAccordionSummary-root {
    background-color: $no-impact;
  }
}
