@import "../../styles/colors";

.iconHolder {
  margin: 15px;
  svg {
    color: $icon-default;
  }
}
.iconHolderPlain {
  margin: 0 10px 0 0;
}

.iconHolder-critical {
  svg {
    color: $failure;
  }
}

.iconHolder-operational {
  svg {
    color: $success;
  }
}

.iconHolder-major_outage,
.iconHolder-major {
  svg {
    color: $major;
  }
}

.iconHolder-partial_outage {
  svg {
    color: $minor;
  }
}

.iconHolder-minor,
.iconHolder-degraded_performance {
  svg {
    color: $warning;
  }
}

.iconHolder-maintenance,
.iconHolder-under_maintenance {
  svg {
    color: $maintenance;
  }
}

.iconHolder-none {
  svg {
    color: $no-impact;
  }
}
