@import "../../styles/colors";

.wrapper {
  padding: 20px;
}
.componentTable {
  border-spacing: 1px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  .componentName {
    text-align: right;
    min-width: 200px;
  }

}
.componentTableHeader {
  color: $base-color;
  background-color: $cloud-call;
  padding: 20px;
  border-left: 1px solid $cloud-call-highlight ;
}
.componentTableBlank {
  color: $base-color;
  color: transparent;
}
.componentName {
  background-color: $background-highlight;
  padding: 10px;
}
.componentStatus {
  border-left: 1px solid $border-highlight;
}
