@import "../../styles/fonts";

.wrapper {
  padding-top: 5px;
}
.incidentDetailsAccordion {
  margin: 10px;
  .incidentHeader {
    padding: 0 10px ;
    p {
      font-weight: bold;
      font-size: $header-font-size;
    }
  }
}
